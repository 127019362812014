import {
  RequestOptions,
  RestDataSource,
} from '@shapegames/utils/lib/rest-data-source';
import { IS_PRODUCTION, IS_PREPROD } from 'src/constants/enviroment';
import {
  Tag,
  ArticlesGroupsResponse,
  ArticlesGroup,
  ArticlesByTagResponse,
  Article,
  Advertisement,
  AuthorsResponse,
} from './types/article';

type ArticlesApiParams =
  | undefined
  | {
      authToken: string;
    };
export class ArticlesApi extends RestDataSource<ArticlesApiParams> {
  constructor(args?: ArticlesApiParams) {
    super({
      params: args,
      disableRetry: true,
    });
    this.baseURL =
      IS_PRODUCTION || IS_PREPROD
        ? 'https://control-panel-article-api.shapegamesjack.com/api'
        : 'https://control-panel-article-api.shapegamesjacktest.com/api';
  }

  public getTags(allTags = false) {
    return this.get<Tag[]>('tags', {
      shownInMenu: !allTags,
    });
  }

  public getAllAuthors() {
    return this.get<AuthorsResponse>('authors/all');
  }

  public getArticleGroups(nextPageCursor?: string) {
    return this.get<ArticlesGroupsResponse>(
      'article_groups',
      nextPageCursor ? { cursor: nextPageCursor } : '',
    );
  }

  public getArticleGroupBySlug(slug: string, nextPageCursor?: string) {
    return this.get<ArticlesGroup>(
      `article_groups/${slug}/articles`,
      nextPageCursor ? { cursor: nextPageCursor } : '',
    );
  }

  public getArticlesByTag(tag: string, nextPageCursor?: string) {
    return this.get<ArticlesByTagResponse>(
      `tags/${tag}/articles`,
      nextPageCursor ? { cursor: nextPageCursor } : '',
    );
  }

  public getArticleBySlug(slug: string) {
    return this.get<Article>(`articles/${slug}`);
  }

  /** Protected endpoint */
  public getArticlePreviewById(id: string) {
    return this.get<Article>(`articles/${id}/preview`);
  }

  public searchArticles(tags: string[]) {
    const queryString = tags.map(tag => `tags=${tag}`).join('&');
    return this.get<ArticlesByTagResponse>(`articles/search`, queryString);
  }

  public getRandomAdvertisements(numOfAdverts?: number) {
    const validNumOfAdverts =
      typeof numOfAdverts === 'number' && numOfAdverts > 0;
    return this.get<Advertisement[]>(
      'advertisements/random',
      validNumOfAdverts ? `n=${numOfAdverts}` : '',
    );
  }

  willSendRequest(request: RequestOptions) {
    if (!this.params) {
      return;
    }

    const { authToken } = this.params;

    request.headers = request.headers || ({} as Record<string, string>);

    if (authToken) {
      request.headers = {
        Authorization: `Bearer ${authToken}`,
      };
    }
  }
}
