import React, { PropsWithChildren, useCallback } from 'react';
import PagingLoader from './PagingLoader';
import useVericalIntersect from 'src/hooks/use-vertical-interesect';

interface InfinityLoadingProps {
  onFetchMore: () => void;
  isLoading: boolean;
  shouldFetchMore: boolean;
  startFetchingOffeset?: number;
}

function InfinityLoading({
  onFetchMore,
  shouldFetchMore,
  isLoading,
  startFetchingOffeset = 500,
  children,
}: PropsWithChildren<InfinityLoadingProps>) {
  const handleObserver: IntersectionObserverCallback = useCallback(
    entities => {
      const target = entities[0];
      if (target.isIntersecting && shouldFetchMore) {
        onFetchMore();
      }
    },
    [shouldFetchMore, onFetchMore],
  );
  const loader = useVericalIntersect<HTMLDivElement>(
    handleObserver,
    `${startFetchingOffeset}px`,
  );
  return (
    <>
      {children}
      <PagingLoader ref={loader} isLoading={isLoading} />
    </>
  );
}

export default InfinityLoading;
