import styled from 'styled-components';
import React from 'react';
import { Article } from 'src/services/articles';
import LayoutGridPrimary from './layouts/GridPrimary';
import LayoutGridSecondary from './layouts/GridSecondary';
import LayoutStack from './layouts/Stack';
import GridStack from './layouts/GridStack';
import ArticleHeading from '@shapegames/quick-draw-ui/lib/ArticleHeading';
import Link from '../../../components/Link';

export type ArticlesGroupLayouts =
  | 'gridPrimary'
  | 'gridSecondary'
  | 'gridStack'
  | 'stack';

type LayoutComponentType = typeof LayoutGridPrimary;

const mapLayoutToComponent: Record<ArticlesGroupLayouts, LayoutComponentType> =
  {
    gridPrimary: LayoutGridPrimary,
    gridSecondary: LayoutGridSecondary,
    stack: LayoutStack,
    gridStack: GridStack,
  };

interface ArticlesGroupProps {
  layout: ArticlesGroupLayouts;
  title?: string;
  items: Article[];
  slug?: string;
}

function ArticlesGroup({
  title,
  items,
  layout = 'gridStack',
  slug,
}: ArticlesGroupProps) {
  const Layout = mapLayoutToComponent[layout];
  return (
    <ArticlesGroupCss>
      <ArticlesGroupHeaderCss>
        {title && <ArticleHeading>{title}</ArticleHeading>}
        {slug && (
          <Link href={`/groups/${slug}`}>
            <ArticleMoreButtonLinkCss>More</ArticleMoreButtonLinkCss>
          </Link>
        )}
      </ArticlesGroupHeaderCss>
      <Layout articles={items} />
    </ArticlesGroupCss>
  );
}

const ArticlesGroupCss = styled.section`
  ${p => p.theme.breakpoints.sm} {
    display: grid;
  }
`;

const ArticleMoreButtonLinkCss = styled.div`
  ${p => p.theme.getFontStyle('primary', 'semiBold')}
  font-size: ${p => p.theme.typography.textXs};
  line-height: 1.6;
  background: ${p => p.theme.colors.gray300};
  color: ${p => p.theme.colors.black};
  padding: ${({ theme: { sizes } }) => `${sizes.spacing1} ${sizes.spacing2}`};
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
    box-shadow: ${p => p.theme.boxShadows.xs};
  }
`;

const ArticlesGroupHeaderCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.spacing5} !important;
`;

export default ArticlesGroup;
